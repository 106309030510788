import React from "react";
import Icon from "../../components/ui/Icon";
import * as styles from "./styles.module.scss";

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.wrapper}>
        <div className={styles.upperWrapper}>
          <p className={styles.text}>
            <span className="text-12">WENN DANN ÖVB</span>
            <br />
            Wenn Sie gerne persönlich beraten werden, leistungsstarke Produkte
            zu fairen Beiträgen gut finden und wenn Sie sich im Schadenfall auf
            schnelle und unkomplizierte Hilfe verlassen wollen, dann haben Sie
            mit uns den richtigen Partner an Ihrer Seite. Einen Partner, der
            sich nicht nur für Sie und die Menschen in Niedersachsen stark
            macht, sondern auch für Sport, Kultur, Soziales und die Feuerwehren
            in Ihrer Region. WENN, DANN ÖVB. Fair versichert.
            <br />
            <br />
            *Wenn Sie sich für den ÖVB-Newsletter angemeldet haben und diesen
            bis einschließlich 31.12.2023 abonnieren, können Sie am Gewinnspiel
            teilnehmen.
            <br />
            <br />
            Teilnahmebedingungen:
            <br />
            Teilnahmezeitraum ist der 17.07.2023 – 31.12.2023, 23:59 Uhr. Zu
            gewinnen ist 20 Mal je ein Rossmann-Gutschein im Wert von 50 EUR.
            Die Teilnahme erfolgt gegen die Bereitstellung der E-Mail-Adresse
            zum Newsletterempfang bis einschließlich 31.12.2023. Die Teilnahme
            erfolgt im Übrigen entgeltfrei. Die Gewinner werden unter allen
            Teilnehmern, die sich bis zum 31.12.2023 für den Newsletter
            angemeldet haben und diesen bis zu diesem Zeitpunkt abonnieren, bis
            zum 04.01.2024 ausgelost und per E-Mail benachrichtigt. Teilnahme
            ist ab 18 Jahren möglich. Teilnahmeberechtigt sind nur Teilnehmer
            mit ständigem Wohnsitz in Niedersachsen und Bremen; Mitarbeiter des
            Veranstalters und von Unternehmen der S-Finanzgruppe sind von der
            Teilnahme ausgeschlossen. Pro Person ist nur eine Teilnahme möglich.
            Der Rechtsweg sowie eine Barauszahlung des Gewinns sind
            ausgeschlossen. Die vollständigen Teilnahmebedingungen und
            Datenschutzhinweise sind hier abrufbar. Bezüglich der Einzelheiten
            zum Newsletterempfang wird auf die Datenschutzbestimmungen
            verwiesen.
          </p>
          <div className={styles.iconWrapper}>
            <a
              href="https://www.facebook.com/oevbversicherungen/"
              target="_blank"
              rel="noreferrer"
            >
              <Icon
                name="facebook"
                width={22}
                additionalClass="text-whiteGray"
              />
            </a>
            <a
              href="https://www.instagram.com/oevbversicherungen/"
              target="_blank"
              rel="noreferrer"
            >
              <Icon
                name="instagram"
                width={22}
                additionalClass="text-whiteGray"
              />
            </a>
          </div>
        </div>
        <div className={styles.belowWrapper}>
          <div className={styles.text}>
            <span>© 2023 ÖVB Versicherungen</span>{" "}
            <a
              href="https://www.oevb.de/content/impressum/"
              target="_blank"
              rel="noreferrer"
            >
              Impressum
            </a>
            &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
            <a
              href="https://www.oevb.de/content/datenschutz/"
              target="_blank"
              rel="noreferrer"
            >
              Datenschutz
            </a>
            &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
            <a
              href="https://www.oevb.de/content/privat/service/kontakt/"
              target="_blank"
              rel="noreferrer"
            >
              Kontakt
            </a>
            &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
            <a title="Cookie-Einstellungen">Cookie-Einstellungen</a>
          </div>
          <div className={styles.logoWrapper}>
            <Icon name="sparkasse" width={105} />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
