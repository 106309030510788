import React, { ReactChild } from 'react';
import c from 'classnames';
import * as styles from './style.module.scss';

type HeadlineProps = {
  level?: 1 | 2 | 3 | 4 | 5 | 6;
  classLevel?: 1 | 2 | 3 | 4 | 5 | 6;
  centered?: boolean;
  color?: 'Primary' | 'Secondary' | 'White' | 'Black';
  additionalClass?: string;
  children: ReactChild;
};

const Headline = ({
  children,
  level = 2,
  classLevel,
  centered = false,
  color = 'Primary',
  additionalClass,
}: HeadlineProps) => {
  const HeadlineTag = ({ ...props }) => React.createElement(`h${level}`, props, children);
  const headlineClass = !classLevel ? level : classLevel;
  return (
    <HeadlineTag
      className={c(
        styles[`headline${headlineClass}`],
        {
          'text-center': centered,
          'text-primary': color === 'Primary',
          'text-black': color === 'Black',
          'text-white': color === 'White',
        },
        additionalClass
      )}
    >
      {children}
    </HeadlineTag>
  );
};

export default Headline;
