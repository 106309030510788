import React, { ReactNode } from 'react';
import c from 'classnames';

type RowProps = {
  nogap?: boolean;
  additionalClass?: string;
  children: ReactNode;
};

const Row = ({ children, nogap = false, additionalClass }: RowProps) => (
  <div className={c('grid grid-cols-12 tablet:grid-cols-12', { 'tablet:gap-8': !nogap }, additionalClass)}>
    {children}
  </div>
);

export default Row;
