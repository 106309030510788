import React, { ReactNode } from 'react';
import c from 'classnames';

type RowProps = {
  mobile?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  tablet?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  tabletLandscape?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  desktop?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  centered?: boolean;
  additionalClass?: string;
  children: ReactNode;
};

const Col = ({ mobile, tablet, tabletLandscape, desktop, centered, children, additionalClass }: RowProps) => {
  const centeredTabletClass = {
    1: 'tablet:col-start-1',
    2: 'tablet:col-start-2',
    3: 'tablet:col-start-3',
    4: 'tablet:col-start-4',
    5: 'tablet:col-start-5',
    6: 'tablet:col-start-6',
    7: 'tablet:col-start-6',
    8: 'tablet:col-start-6',
    9: 'tablet:col-start-6',
    10: 'tablet:col-start-6',
    11: 'tablet:col-start-6',
    12: 'tablet:col-start-6',
  };
  const centeredTabletLandscapeClass = {
    1: 'tabletLandscape:col-start-1',
    2: 'tabletLandscape:col-start-2',
    3: 'tabletLandscape:col-start-3',
    4: 'tabletLandscape:col-start-4',
    5: 'tabletLandscape:col-start-5',
    6: 'tabletLandscape:col-start-6',
    7: 'tabletLandscape:col-start-6',
    8: 'tabletLandscape:col-start-6',
    9: 'tabletLandscape:col-start-6',
    10: 'tabletLandscape:col-start-6',
    11: 'tabletLandscape:col-start-6',
    12: 'tabletLandscape:col-start-6',
  };
  const centeredDesktopClass = {
    1: 'desktop:col-start-1',
    2: 'desktop:col-start-2',
    3: 'desktop:col-start-3',
    4: 'desktop:col-start-4',
    5: 'desktop:col-start-5',
    6: 'desktop:col-start-6',
    7: 'desktop:col-start-6',
    8: 'desktop:col-start-6',
    9: 'desktop:col-start-6',
    10: 'desktop:col-start-6',
    11: 'desktop:col-start-6',
    12: 'desktop:col-start-6',
  };
  return (
    <div
      className={c(
        {
          'desktop:col-span-2': desktop === 2,
          'desktop:col-span-3': desktop === 3,
          'desktop:col-span-4': desktop === 4,
          'desktop:col-span-5': desktop === 5,
          'desktop:col-span-6': desktop === 6,
          'desktop:col-span-7': desktop === 7,
          'desktop:col-span-8': desktop === 8,
          'desktop:col-span-9': desktop === 9,
          'desktop:col-span-10': desktop === 10,
          'desktop:col-span-11': desktop === 11,
          'desktop:col-span-12': desktop === 12,
          'tablet:col-span-2': tablet === 2,
          'tablet:col-span-3': tablet === 3,
          'tablet:col-span-4': tablet === 4,
          'tablet:col-span-5': tablet === 5,
          'tablet:col-span-6': tablet === 6,
          'tablet:col-span-7': tablet === 7,
          'tablet:col-span-8': tablet === 8,
          'tablet:col-span-9': tablet === 9,
          'tablet:col-span-10': tablet === 10,
          'tablet:col-span-11': tablet === 11,
          'tablet:col-span-12': tablet === 12,
          'tabletLandscape:col-span-2': tabletLandscape === 2,
          'tabletLandscape:col-span-3': tabletLandscape === 3,
          'tabletLandscape:col-span-4': tabletLandscape === 4,
          'tabletLandscape:col-span-5': tabletLandscape === 5,
          'tabletLandscape:col-span-6': tabletLandscape === 6,
          'tabletLandscape:col-span-7': tabletLandscape === 7,
          'tabletLandscape:col-span-8': tabletLandscape === 8,
          'tabletLandscape:col-span-9': tabletLandscape === 9,
          'tabletLandscape:col-span-10': tabletLandscape === 10,
          'tabletLandscape:col-span-11': tabletLandscape === 11,
          'tabletLandscape:col-span-12': tabletLandscape === 12,
          'col-span-2': mobile === 2,
          'col-span-3': mobile === 3,
          'col-span-4': mobile === 4,
          'col-span-5': mobile === 5,
          'col-span-6': mobile === 6,
          'col-span-7': mobile === 7,
          'col-span-8': mobile === 8,
          'col-span-9': mobile === 9,
          'col-span-10': mobile === 10,
          'col-span-11': mobile === 11,
          'col-span-12': mobile === 12,
          [centeredTabletClass[(12 - (tablet || 0)) / 2 + 1]]: centered,
          [centeredTabletLandscapeClass[(12 - (tabletLandscape || 0)) / 2 + 1]]: centered,
          [centeredDesktopClass[(12 - desktop || 0) / 2 + 1]]: centered,
        },
        additionalClass
      )}
    >
      {children}
    </div>
  );
};

export default Col;
